import { useRouter } from 'next/router'
import { ReactElement, useEffect } from 'react'
import pathConfiguration from 'src/configuration/path'
import { PAGES_NOT_FETCH_API } from '~/core/constants/url'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import useBoundStore from '~/lib/store'

const WithTaskBadgeCount = (props: {
  children: (props: { totalCount: number | undefined }) => ReactElement
}) => {
  const router = useRouter()
  const { clientGraphQL } = useContextGraphQL()
  const totalCount = useBoundStore((state) => state.taskCount)
  const fetchTaskBadgeCount = useBoundStore(
    (state) => state.fetchTaskBadgeCount
  )
  useEffect(() => {
    if (
      router?.pathname.includes(pathConfiguration.requisitions.list) &&
      !!router.query.id &&
      !!router.query.userToken
    )
      return

    if (!PAGES_NOT_FETCH_API.some((url) => router?.pathname.startsWith(url)))
      fetchTaskBadgeCount(clientGraphQL)
  }, [router])
  return props.children({ totalCount })
}

export default WithTaskBadgeCount
