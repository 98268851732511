import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { jobConvertSalary } from '~/lib/features/jobs/utilities/common'

const JobViewSalary: FC<{
  salaryFrom: number
  salaryTo: number
  currency: string
  typeOfSalaryDescription: string
}> = ({ salaryFrom, salaryTo, currency, typeOfSalaryDescription }) => {
  const { t } = useTranslation()

  if (
    (salaryFrom === null && salaryTo === null) ||
    (salaryFrom === 0 && salaryTo === 0)
  )
    return <>{t('label:viewSalary:negotiableSalary')}</>

  if (salaryFrom == salaryTo)
    return (
      <>
        {`${jobConvertSalary(
          salaryFrom
        )} ${currency} (${typeOfSalaryDescription})`}
      </>
    )

  if (salaryFrom > 0 && salaryTo > 0)
    return (
      <>
        {`${jobConvertSalary(salaryFrom)} - ${jobConvertSalary(
          salaryTo
        )} ${currency} (${typeOfSalaryDescription})`}
      </>
    )

  if (salaryTo > 0)
    return (
      <>
        {`${t('label:viewSalary:salaryTo', {
          salaryTo: `${jobConvertSalary(salaryTo)} ${currency}`
        })} (${typeOfSalaryDescription})`}
      </>
    )

  if (salaryFrom > 0)
    return (
      <>
        {`${t('label:viewSalary:salaryFrom', {
          salaryFrom: `${jobConvertSalary(salaryFrom)} ${currency}`
        })} (${typeOfSalaryDescription})`}
      </>
    )
  return <div />
}

export default JobViewSalary
