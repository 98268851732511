import { gql } from 'urql'

const QueryTenantReferralAgencySettingShow = gql`
  query {
    tenantReferralSettingShow {
      id
      values
      attachments {
        name
        id
        file
        blobs
      }
      enablingRegisterChub
      chubRegistrationType
      restrictEmailDomains
      loginMethods
      description
    }
  }
`

export default QueryTenantReferralAgencySettingShow
